import { listLogs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllAuditLogs = {
  data() {
    return {
      overlay: false,
      noDataText:"",
      GetAllAuditLogsList: [],
    };
  },
  methods: {
    async GetAllAuditLogsMethod(organization_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listLogs, {
            input: {
              organization_id,
            },
          })
        );
        this.GetAllAuditLogsList = JSON.parse(result.data.ListLogs).data.items;
        this.overlay = false;
      } catch (error) {
        this.GetAllAuditLogsList = [];
        this.noDataText = error.errors[0].message
        this.overlay = false;
      }
    },
  },
};
