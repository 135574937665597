<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Audit Logs</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              label="Organization Name"
              class="field_height field_label_size FontSize maxWidthLarge mt-6 mr-2"
              :items="GetAllOrganizationList"
              item-text="organization_name"
              item-value="organization_id"
              v-model="organization_id"
              @change="GetAllAuditLogsMethod(organization_id)"
            ></v-select>
            <v-text-field dense outlined class="field_height field_label_size FontSize maxWidthLarge mt-6 mr-2" v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
            <v-btn color="primary" @click="downloadExcel" class="mt-n1" small><v-icon small class="mr-1">mdi-download</v-icon> Export </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :headers="auditLogHeaders" :items="GetAllAuditLogsList" :search="search" :no-data-text="noDataText">
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllAuditLogsList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.audit_type`]="{ item }">
            <div class="FontSize">
              {{ item.audit_type.replaceAll("_", " ") }}
            </div>
          </template>
          <template v-slot:[`item.audit_added_on`]="{ item }">
            <div class="FontSize">
              {{ item.audit_added_on ? new Date(item.audit_added_on).toLocaleString() : "-" }}
            </div>
          </template>
          <template v-slot:[`item.audit_added_by`]="{ item }">
            <div class="FontSize">
              {{ item.audit_added_by ? item.audit_added_by : "-" }}
            </div>
          </template>
          <template v-slot:[`item.audit_action`]="{ item }">
            <div class="FontSize">
              {{ item.audit_action }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
let xlsx = require("json-as-xlsx");

import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllAuditLogs } from "@/mixins/GetAllAuditLogs.js";
import { GetAllOrganizations } from "@/mixins/GetAllOrganizations.js";
export default {
  components: {
    Overlay,
  },
  mixins: [GetAllAuditLogs, GetAllOrganizations],
  data: () => ({
    search: "",
    overlay: false,
    organization_id: "",
    GetAllAuditLogsList: [],
    auditLogHeaders: [
      {
        text: "Module Name",
        value: "audit_type",
      },
      {
        text: "Action",
        value: "audit_action",
      },
      {
        text: "Taken By",
        value: "audit_added_by",
      },
      {
        text: "Taken On",
        value: "audit_added_on",
      },
    ],
    StoreObj: {},
    dialogDeleteUser: false,
  }),
  watch: {
    GetAllOrganizationList(value) {
      if (value && value.length != 0) {
        this.organization_id = value[0].organization_id;
        this.GetAllAuditLogsMethod(value[0].organization_id);
      }
    },
  },
  mounted() {
    this.GetAllOrganizationsMethod();
  },
  methods: {
    downloadExcel() {
      let content = this.GetAllAuditLogsList.filter((item) => ((item.audit_type = item.audit_type.replaceAll("_", " ")), (item.audit_added_on = new Date(item.audit_added_on).toLocaleString())));
      let data = [
        {
          sheet: "Audit logs",
          columns: [
            {
              label: "Module Name",
              value: "audit_type",
            },
            {
              label: "Action",
              value: "audit_action",
            },
            {
              label: "Taken By",
              value: "audit_added_by",
            },
            {
              label: "Taken On",
              value: "audit_added_on",
            },
          ],
          content: content,
        },
      ];
      let settings = {
        fileName: "Audit-Logs",
        extraLength: 4,
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};
</script>
<style></style>
